import Vue from 'vue'

// Google analytics
import VueGtag from "vue-gtag";

import App from './App.vue'

// Google analytics config
Vue.use(VueGtag, {
  config: { id: "G-R0PKWMX7T9" }
});

Vue.config.productionTip = false

// import css
import 'leaflet/dist/leaflet.css';
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import './index.css'

// Inline svg plugin
import {InlineSvgPlugin} from 'vue-inline-svg';
Vue.use(InlineSvgPlugin);

new Vue({
  render: h => h(App),
}).$mount('#app')
