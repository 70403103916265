<template>
  <div id="app">

    <l-map style="height: 100vh" :zoom="mapZoom" :minZoom="minZoom" :maxZoom="maxZoom" :center="mapCenter" :options="{zoomControl: false}">
      <l-tile-layer :url="tileLayerUrl" ></l-tile-layer>
      <!-- <l-tile-layer :url="secondtileLayerUrl" ></l-tile-layer> -->
      <l-control-zoom position="topright"  ></l-control-zoom>
      <l-control :position="'topleft'" class="paj-logo">
        <img src="logo-paj.svg">
      </l-control>
      <v-marker-cluster :options="clusterOptions" >
        <l-marker v-for="place in filteredPlaces" :key="place.id" :lat-lng="place.latLng" @click="openMarker(place.name)">
          <l-popup>
            <div class="paj-popup">
              <img class="popup-image" :src="place.image"><br>
              <span class="popup-name">{{ place.name }}</span>
              <a class="popup-button" :href="place.tour360" target="_blank" @click="openVirtualTour(place.name)">Allez-y!</a>
            </div>
          </l-popup>
        </l-marker>
      </v-marker-cluster>

    </l-map>
  </div>
</template>

<script>
// import json data
import placesData from "/public/places.json";

// Import leaflet needed components
import { LMap, LTileLayer, LMarker, LPopup, LControlZoom, LControl } from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

// Using marker icons
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('@/assets/img/map-marker.svg'),
  iconUrl: require('@/assets/img/map-marker.svg'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  iconSize: [45, 55.95],
  iconAnchor: [22.5, 55.95]
});


export default {
  name: 'App',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControlZoom,
    LControl,
    'v-marker-cluster': Vue2LeafletMarkerCluster
  },
  data () {
    return {
      //tileLayerUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      tileLayerUrl: 'https://api.mapbox.com/styles/v1/optik3600/citcxiocs000m2hp6o9bdi84l/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib3B0aWszNjAwIiwiYSI6ImNpcHU2ZW05YzA5MnJmb20yY3l2ODM0N3QifQ.rQoBRO7yVvEs1-Rur7pcVQ',
      //tileLayerUrl: 'http://c.tile.stamen.com/watercolor/{z}/{x}/{y}.jpg',
      // tileLayerUrl: 'http://b.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
      //tileLayerUrl: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
      // tileLayerUrl: 'https://tiles.wmflabs.org/osm-no-labels/{z}/{x}/{y}.png',
      secondtileLayerUrl: 'https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png',
      mapZoom: 7,
      minZoom: 5,
      maxZoom: 12,
      mapCenter: [50.3524188, -67.2750917],
      places: placesData,
      currentRegion: "",
      clusterOptions: {
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        spiderfyOnMaxZoom: true,
        animate: true,
      },
    };
  },
  created() {
    var currentUrl = window.location.href;
    let url = new URL(currentUrl);
    let params = new URLSearchParams(url.search);
    this.currentRegion = params.get('region');
  },
  computed: {
    filteredPlaces() {
      if (this.currentRegion == "minganie" || this.currentRegion == "7rivieres"){
        return this.places.filter(place => place.region == this.currentRegion);
      }
      else{
        return this.places;
      }
    }
  },
  methods: {
    openVirtualTour: function(name){
      this.$gtag.event('view_item', {
        'event_category': 'Consultation visite 360',
        'event_label': name + " (360)",
        'value': 1
      });
    },
    openMarker: function(name){
      this.$gtag.event('view_item', {
        'event_category': 'Ouverture de point sur la carte',
        'event_label': name + " (Carte)",
        'value': 1
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() =>{
        this.clusterOptions = { disableClusteringAtZoom: 10 }
      });
    }, 5000);

  }
}
</script>
